import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SnackbarService } from '@app/shared/services/snackbar.service';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sample-control-radio',
  templateUrl: './sample-control-radio.component.html',
  styleUrl: './sample-control-radio.component.scss',
})
export class SampleControlRadioComponent extends FieldType {
  opts: any[] = [];
  expectedAnswer: any;
  commentOption: any;
  attachmentOption: any;
  showComment: boolean = false;
  comment: string = '';
  showAttachment: boolean = false;
  attachment: any = null;
  attachmentIcon: string = '/assets/icons/no-document-light.svg';

  fileInputText: string | undefined = '';

  constructor(
    private sanitizer: DomSanitizer,
    private snackbarService: SnackbarService
  ) {
    super();
  }

  ngOnInit() {
    if (this.props.options) {
      if (Array.isArray(this.props.options)) {
        this.opts = this.props.options;
      } else if (this.props.options instanceof Observable) {
        this.props.options.subscribe((opts: any[]) => {
          this.opts = opts;
        });
      }
    }
    if (this.props['initialComment']) {
      this.showComment = true;
      this.comment = this.props['initialComment'];
    }

    if (this.props['initialAttachment']) {
      this.showAttachment = true;
      this.attachment = this.props['initialAttachment'];
      this.attachmentIcon = '/assets/icons/document-light.svg';
      this.fileInputText = 'Pièce jointe';
    }

    this.expectedAnswer = this.props['expectedAnswer'];
    this.commentOption = this.props['configuration']['comment'];
    this.attachmentOption = this.props['configuration']['attachment'];

    if (this.commentOption && this.commentOption.is_allowed) {
      if (this.commentOption.only_if_unvalid) {
        this.formControl.valueChanges.subscribe((value) => {
          if (
            this.expectedAnswer.values &&
            this.expectedAnswer.values.length == 1
          ) {
            const values: string[] = this.expectedAnswer.values;
            if (values[0] !== value) {
              this.showComment = true;
            } else {
              this.showComment = false;
              this.comment = '';
            }
          }
        });
      } else {
        this.showComment = true;
      }
    }

    if (this.attachmentOption && this.attachmentOption.is_allowed) {
      if (this.attachmentOption.only_if_unvalid) {
        this.formControl.valueChanges.subscribe((value) => {
          if (
            this.expectedAnswer.values &&
            this.expectedAnswer.values.length == 1
          ) {
            const values: string[] = this.expectedAnswer.values;
            if (values[0] !== value) {
              this.showAttachment = true;
            } else {
              this.showAttachment = false;
              this.attachment = null;
            }
          }
        });
      } else {
        this.showAttachment = true;
      }
    }
  }

  getFormControl(): FormControl {
    const formControl = this.field.formControl as FormControl;
    if (this.props.disabled) {
      formControl.disable();
    }
    return formControl;
  }

  pickFile(event: any): void {
    const file = event.target.files[0];
    this.getFormControl().setValue(file);
  }

  onFileSelected(event: Event) {
    const files: any = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      var idxDot = files[0].name.lastIndexOf('.') + 1;
      var extFile = files[0].name
        .substr(idxDot, files[0].name.length)
        .toLowerCase();
      if (!(extFile == 'jpg' || extFile == 'jpeg' || extFile == 'png')) {
        return;
      }
      if (files[0].size > 1024 * 1000 * 10) {
        // > 10 Mo
        this.snackbarService.openSnackBar(
          (this.snackbarService.lang = 'fr'
            ? 'Le fichier est trop volumineux (max 10Mo)'
            : 'File is too big (max 10Mo)')
        );
        return;
      }
      this.attachmentIcon = '/assets/icons/document-light.svg';
      this.fileInputText = files[0].name;
      const url = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(files[0])
      );
      files[0]['url'] = url;
      this.attachment = files[0];
    } else {
      this.attachmentIcon = '/assets/icons/no-document-light.svg';
    }
  }

  deleteFiles() {
    this.attachment = null;
    this.attachmentIcon = '/assets/icons/no-document-light.svg';
    this.fileInputText = undefined;
  }
}
